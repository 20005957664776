import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Button, Container } from "@mui/material";
import Header_Layout from "../../components/layouts/Header_Layout";
import { HeadFC, navigate } from "gatsby";
import Desktop from "../../components/desktop/Desktop";


const page_styles = {
  paddingTop: 8,
  paddingLeft: `0px !important`,
  backgroundColor: "#39B54A",
  minHeight: '70vh'
};

const Landing = () => {
  return (
    <Header_Layout fullWidth={true}>
      <Container maxWidth={false} sx={page_styles}>
        <Grid container spacing={4} alignItems="flex-end" sx={{ p: 0 }}>
          <Grid item xs={12}>
            <Typography
              sx={{ color: "#fff" }}
              component="h1"
              variant="h3"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Thinking about selling your desktop?
            </Typography>
            <Typography sx={{ color: "#fff" }} align="center">
              We&apos;ll help you sell, upgrade, and/or maintain your build and your
              components.
            </Typography>
            <Grid
              container
              mt={4}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item md={2} sm={0}></Grid>
                  <Grid
                    item
                    xs={12}
                    direction="column"
                    mb={4}
                    alignItems="center"
                    justifyContent={"center"}
                    display={{ xs: "flex", sm: "none", md: "none", lg: "none" }}
                  >
                    <Desktop
                      is_static={false}
                      case_enabled={true}
                      cpu_enabled={true}
                      cpu_cooler_enabled={true}
                      gpu_enabled={true}
                      motherboard_enabled={true}
                      operating_system_enabled={true}
                      psu_enabled={true}
                      ram_enabled={true}
                      storage_enabled={true}
                      case_fan_enabled={true}
                      scale_percentage={50}
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sm={6}
                    mb={4}
                    display={{ xs: "none", sm: "flex", md: "flex", lg: "flex" }}
                  >
                    <Desktop
                      is_static={false}
                      case_enabled={true}
                      cpu_enabled={true}
                      cpu_cooler_enabled={true}
                      gpu_enabled={true}
                      motherboard_enabled={true}
                      operating_system_enabled={true}
                      psu_enabled={true}
                      ram_enabled={true}
                      storage_enabled={true}
                      case_fan_enabled={true}
                      scale_percentage={60}
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                    sx={{ textAlign: "center", p: 4 }}
                  >
                    <Typography
                      variant="h3"
                      mb={4}
                      sx={{ color: "#fff" }}
                      align="center"
                    >
                      Dive deep into your build.
                    </Typography>
                    <Button
                      size={"medium"}
                      variant="contained"
                      sx={{ mb: 4, color: "primary.main", backgroundColor: "background.default" }}
                      onClick={() => navigate("/create")}
                    >
                      {`Create your build`}
                    </Button>
                    <Typography sx={{ color: "#fff" }} align="center" mb={4}>
                      Tell us about your build or component to gain access to
                      upgrade recommendations and to discover your builds
                      worth.
                    </Typography>
                  </Grid>
                  <Grid item md={2} sm={0}></Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Header_Layout >
  );
};

// why is this here? TODO
Landing.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Landing;

export const Head: HeadFC = () => <title>BitLot.app</title>;
